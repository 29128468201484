import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import { RootState } from 'Util/Store/Store.type';
/** @namespace Inov8/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    requestPage(): void {
        const { requestPage } = this.props;
        const params = this.getRequestQueryParams();
        const { id, identifier = '' } = params;
        const {
            actionName: {
                id: pageId = null,
                cmsPage: {
                    identifier: pageIdentifier = null,
                } = {},
            } = {},
        } = window;

        if (!id && !identifier) {
            return;
        }

        if (!id) {
            return;
        }

        // vvv check if cms page was already loaded from action
        if (
            id === pageId
            || identifier.replace(/^\/+/, '') === pageIdentifier
        ) {
            return;
        }

        requestPage(params);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
