/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireSelectPromotion, fireViewPromotion } from '../../event/promotion';
import { debounceCallback } from '../../util/wait';

export const DEBOUNCE_INTERVAL_MS = 2000;

const afterUpdate = debounceCallback(async (args, callback) => {
    await callback(...args);

    // Get all promotion attributes
    const elements = document.querySelectorAll('div[data-promoname]');

    elements.forEach(async (element) => {
        const {
            dataset: {
                promoid: promoID = "",
                promoname: promoName = "",
                promocreative : creativeName = "",
            } = {}
        } = element;

        await fireViewPromotion(promoID, promoName, creativeName);
        // Listen for the click event
        element.addEventListener('click', async () => {
            await fireSelectPromotion(promoID, promoName, creativeName);
        });
    });
}, DEBOUNCE_INTERVAL_MS);

export default {
    'Route/CmsPage/Container': {
        'member-function': {
            componentDidUpdate: afterUpdate,
        },
    },
};
