import Html from 'SourceComponent/Html';
import {
    CmsPageComponent as SourceCmsPageComponent,
} from 'SourceRoute/CmsPage/CmsPage.component';
import NoMatch from 'SourceRoute/NoMatch';
import { ReactElement } from 'SourceType/Common.type';

import './CmsPage.style';

/** @namespace Inov8/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    renderContent(): ReactElement {
        const {
            isLoading,
            cmsPage: { content },
        } = this.props;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!content) {
            return null;
        }

        return <Html content={ content } />;
    }

    render(): ReactElement {
        const {
            cmsPage,
            isBreadcrumbsActive,
            isLoading,
        } = this.props;
        const { page_width, title, content } = cmsPage;

        if (!isLoading && !title && !content) {
            return <NoMatch />;
        }

        return (
            <main
              block="CmsPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
            >
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    <div block="CmsPage" elem="Content">
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );
    }
}

export default CmsPageComponent;
